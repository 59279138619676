<ng-container *ngIf="source && type">
  <div [ngClass]="{'c-container-pdf': type === 'pdf'}">
    <ng-container [ngSwitch]="type">
      <ng-template [ngSwitchCase]="'img'">
        <img [src]="source">
      </ng-template>
      <ng-template [ngSwitchCase]="'video'">
        <video controls>
          <source [src]="source" type="video/webm" />
          <source [src]="source" type="video/mp4" />
        </video>
      </ng-template>
      <ng-template [ngSwitchCase]="'audio'">
        <audio controls>
          <source [src]="source" type="audio/ogg">
          <source [src]="source" type="audio/mpeg">
        </audio>
      </ng-template>
      <ng-template [ngSwitchCase]="'pdf'">
        <pdf-viewer [src]="source" [render-text]="true" [original-size]="true" [fit-to-page]="true" style="display: block;" [zoom]="zoom_to"></pdf-viewer>
        <div class="c-bli">
          <button class="c-button-first" (click)="zoomIn()">+</button>
          <button class="c-button-last" (click)="zoomOut()">-</button>
        </div>
      </ng-template>
    </ng-container>
  </div>
</ng-container>