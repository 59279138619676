import { Injectable } from '@angular/core';
import { CaptureAudioOptions, CaptureImageOptions, CaptureVideoOptions, MediaCapture, MediaFile } from '@awesome-cordova-plugins/media-capture/ngx';
import { FileService } from 'src/app/file.service';
import { blobTo64, dirtyb64ToBlob } from 'src/app/utils/utils';


@Injectable()
export class RecordMediaService {

  audios: any[] = [];
  videos: any[] = [];
  photos: any[] = [];


  constructor(
    private mediaCapture: MediaCapture,
    private fileService: FileService,
  ) { }

  takeAudio() {
    const options: CaptureAudioOptions = {
      limit: 1,
      duration: 30,
    };
    return this.mediaCapture.captureAudio(options)
      .then(async res => {
        console.log(res);
        return res;
      });
  }

  takeVideo() {
    const options: CaptureVideoOptions = {
      limit: 1,
      duration: 30,
      quality: 0
    };
    return this.mediaCapture.captureVideo(options)
      .then(res => {
        console.log(res);
        return res;
      })
  }

  takePhoto() {
    const options: CaptureImageOptions = {
      limit: 1,
    };
    return this.mediaCapture.captureImage(options)
      .then(async res => {
        console.log(res);
        return res;
      });
  }

  treatMediaFile(mediaFile: MediaFile): Promise<string> {
    const mimeType = mediaFile.type;
    const { fullPath, name, type } = mediaFile;
    return this.fileService.readFile(mediaFile.fullPath)
    .then(readfileResult => {
      return typeof readfileResult.data === 'string' ? readfileResult.data : blobTo64(readfileResult.data)
    })
    .then(dataBase64 => `data:${type};base64,${dataBase64}`)
  }

}
