import { Injectable } from '@angular/core';
import { DataService } from './data.service';
import { map, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class EmbedContextService {

  constructor(
    private dataService: DataService
  ) { }

  setEmbedContextTrue(): Promise<boolean> {
    return this.dataService.embedContext.set(true);
  }

  getEmbedContext(): Observable<boolean> {
    return this.dataService.embedContext.get().pipe(
      map(_ => _ || false)
    );
  }

}
