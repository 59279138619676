import { Injectable } from '@angular/core';
import { Preferences } from '@capacitor/preferences';
import { Storage as IonicStorage } from '@ionic/storage-angular';
import { Observable, Subject, concat, defer } from 'rxjs';
import { AppData_User, App_FormCreatorJson, App_Levage_Updateable } from 'src/app/utils/interfaces';
import { Type_App_DataElem } from 'src/app/utils/types';

@Injectable({
  providedIn: 'root'
})
export class DataService {

  createDataBase = this.ionicStorage.create().then(e => true);

  user: Type_App_DataElem<AppData_User> = this.dataServiceElem('user', new Subject<AppData_User | null>(), `capacitor`);
  planLevageListe: Type_App_DataElem<App_FormCreatorJson[]> = this.dataServiceElem('plan_levage_list', new Subject<App_FormCreatorJson[] | null>(), `capacitor`);
  levageListe: Type_App_DataElem<App_Levage_Updateable[]> = this.dataServiceElem('levage_list', new Subject<App_Levage_Updateable[] | null>(), `ionic`);
  embedContext: Type_App_DataElem<boolean> = this.dataServiceElem('embed_context', new Subject<boolean | null>(), `capacitor`);
  siteSelectionne: Type_App_DataElem<number> = this.dataServiceElem('site_selectionne', new Subject<number | null>, `ionic`);

  datasToClearOnLogout: Type_App_DataElem<any>[] = [
    this.user,
    this.planLevageListe,
    this.siteSelectionne
  ];


  constructor(
    private ionicStorage: IonicStorage
  ) { }

  dataServiceElem<T>(str: string, subject: Subject<T | null>, storage: `capacitor` | `ionic`): Type_App_DataElem<T> {

    const operations = storage === 'capacitor'
      ? this.capacitorDatasFunctions<T>(str, subject)
      : this.ionicDatasFunctions<T>(str, subject);

    const { get, set, observe, remove } = operations;

    return {
      // lastUpdate: null,
      str,
      asObs: subject.asObservable(),
      get,
      set: function (e: T) {
        return set(e).then(_ => {
          // this.lastUpdate = new Date();
          return _;
        });
      },
      observe,
      remove: function () {
        return remove().then(_ => {
          // this.lastUpdate = new Date();
          return _;
        });
      }
    }
  }

  capacitorDatasFunctions<T>(str: string, subject: Subject<T | null>) {
    const get = () => defer(() => this.storageGetCapacitor<T>(str));
    const set = (e: T) => this.storageSetCapacitor<T>(str, e, subject);
    const observe = () => this.dataObserve(get(), subject);
    const remove = () => this.storageRemoveCapacitor(str, subject);
    return { get, set, observe, remove };
  }

  ionicDatasFunctions<T>(str: string, subject: Subject<T | null>) {
    const get = () => defer(() => this.storageGetIonic<T>(str));
    const set = (e: T) => this.storageSetIonic<T>(str, e, subject);
    const observe = () => this.dataObserve(get(), subject);
    const remove = () => this.storageRemoveIonic(str, subject);
    return { get, set, observe, remove };
  }

  dataObserve<T>(getFromStorage$: Observable<T | null>, subject: Subject<T | null>) {
    return concat(
      getFromStorage$,
      subject
    );
  }

  storageGetCapacitor<T>(key: string): Promise<T | null> {
    return Preferences.get({ key })
      .then(e => e.value !== null ? JSON.parse(e.value) : null)
      .catch(err => {
        throw new Error(err);
      });
  }


  storageGetIonic<T>(key: string): Promise<T | null> {
    return this.createDataBase
      .then(_ => this.ionicStorage.get(key)
        .then(e => e !== null ? JSON.parse(e) as T : null)
        .catch(err => {
          throw new Error(err);
        })
      );
  }

  storageSetCapacitor<T>(key: string, value: T, dataHasBeenModified?: Subject<T | null>): Promise<T> {
    return Preferences.set({ key, value: JSON.stringify(value) })
      .then(_ => {
        if (dataHasBeenModified !== undefined) {
          dataHasBeenModified.next(value);
        }
        return value;
      })
      .catch(err => {
        throw new Error(err);
      });
  }

  storageSetIonic<T>(key: string, value: T, dataHasBeenModified?: Subject<T | null>): Promise<T> {
    return this.createDataBase
      .then(_ => this.ionicStorage.set(key, JSON.stringify(value))
        .then(_ => {
          if (dataHasBeenModified !== undefined) {
            dataHasBeenModified.next(value);
          }
          return value;
        })
        .catch(err => {
          throw new Error(err);
        })
      );
  }

  storageRemoveCapacitor<T>(key: string, dataHasBeenModified?: Subject<T | null>): Promise<null> {
    return Preferences.remove({ key })
      .then(_ => {
        if (dataHasBeenModified !== undefined) {
          dataHasBeenModified.next(null);
        }
        return null
      })
      .catch(err => {
        throw new Error(err);
      });
  }

  storageRemoveIonic<T>(key: string, dataHasBeenModified?: Subject<T | null>): Promise<null> {
    return this.createDataBase
      .then(_ => this.ionicStorage.remove(key)
        .then(_ => {
          if (dataHasBeenModified !== undefined) {
            dataHasBeenModified.next(null);
          }
          return null;
        })
        .catch(err => {
          throw new Error(err);
        })
      );
  }

}
