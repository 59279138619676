import { Observable, defaultIfEmpty, forkJoin } from "rxjs";
import { App_FormFieldCasesDynamiques, App_FormFieldCheckbox, App_FormFieldDrawing, App_FormFieldInputNumber, App_FormFieldInputText/*, App_FormFieldListUser*/, App_FormFieldListe, App_FormFieldRadio, App_FormFieldTextArea, App_Levage_Updateable } from "./interfaces";
import { Type_Field, Type_FieldOrLabel, Type_Label } from "./types";

export const arrayIfNullOrUndefined = <T>(a: T[] | null | undefined) => a !== null && a !== undefined ? a : ([] as T[]);

export function instanceOfFieldLabel(object: Type_FieldOrLabel): object is Type_Label {
    return object.type === 'label';
}

export function instanceOfTrueField(object: Type_FieldOrLabel): object is Type_Field {
    return object.type === 'field';
}

export function instanceOfFieldCheckbox(object: Type_Field): object is App_FormFieldCheckbox {
    return object.subType === `checkbox`;
}

export function instanceOfFieldRadio(object: Type_Field): object is App_FormFieldRadio {
    return object.subType === `radio`;
}

export function instanceOfFieldtextArea(object: Type_Field): object is App_FormFieldTextArea {
    return object.subType === `textarea`;
}

export function instanceOfFieldInputText(object: Type_Field): object is App_FormFieldInputText {
    return object.subType === `input-text`;
}

export function instanceOfFieldInputNumber(object: Type_Field): object is App_FormFieldInputNumber {
    return object.subType === `input-number`;
}

export function instanceOfFieldListe(object: Type_Field): object is App_FormFieldListe {
    return object.subType === `liste`;
}

/*
export function instanceOfFieldListUser(object: Type_Field): object is App_FormFieldListUser {
    return object.subType === `list_user`;
}
*/

export function instanceOfFieldDrawing(object: Type_Field): object is App_FormFieldDrawing {
    return object.subType === `drawing`;
}

export function instanceOfCasesDynamiques(object: Type_Field): object is App_FormFieldCasesDynamiques {
    return object.subType === `case_dyn`;
}

export const evaluateInitialvalue = (field: Type_Field, dataLevage: App_Levage_Updateable | undefined) => {
    const dataField = (dataLevage?.data?.datas || []).find(fieldInData => fieldInData.name === field.name)
    return dataField ? dataField.value
    : evaluateInitialValueNoData(field)
}

export const evaluateInitialValueNoData = (field: Type_Field) => {
    return instanceOfFieldCheckbox(field)
    ? false
    : instanceOfFieldInputText(field)
    ? ''
    : instanceOfFieldListe(field)
    ? null
    /*
    : instanceOfFieldListUser(field)
    ? null
    */
    : instanceOfFieldDrawing(field)
    ? ''
    : null
}

/**
 * fonction pour s'assurer qu'une collection d'observable en
 * forkJoin retourne un tableau vide si la collection l'est plutôt que never
 * @param obs un tableau d'observables
 * @returns un tableau vide si le tableau d'observables est vide
 */
export function securityForkJoin<T>(obs: Observable<T>[]): Observable<T[]> {
    return forkJoin(obs).pipe(defaultIfEmpty([]));
  }

const getFileReader: () => FileReader = () => {
    const fileReader = new FileReader();
    const zoneOriginalInstance = (fileReader as any)[`__zone_symbol__originalInstance`];
    return zoneOriginalInstance || fileReader;
};

export const blobTo64: (blob: Blob) => Promise<string> = (blob: Blob) => new Promise(resolve => {
    const reader = getFileReader();
    reader.onload = () => {
        const base64String = reader.result as string;
        resolve(base64String);
    };
    reader.readAsDataURL(blob);
});

export const isValidDate = (d: Date) => d instanceof Date && !Number.isNaN(d.getTime());

export const dirtyb64ToBlob: (b64Data: string, contentType: string) => Blob = (b64Data: string, contentType: string) => {
    contentType = contentType || '';
    const sliceSize = 512;
    const byteCharacters = atob(b64Data);
    const byteArrays = [];
    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      const slice = byteCharacters.slice(offset, offset + sliceSize);
      const byteNumbers = new Array(slice.length);
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }
      const byteArray = new Uint8Array(byteNumbers);
      byteArrays.push(byteArray);
    }
    const blob = new Blob(byteArrays, { type: contentType });
    return blob;
};

export const removeFirstSlash = (str: string) => str.replace(/^\//, '');