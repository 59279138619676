import { Injectable } from '@angular/core';
import { DataService } from './data.service';
import { map, Observable, of, switchMap, take } from 'rxjs';
import { AppDataUserSite } from 'src/app/utils/interfaces';

@Injectable({
  providedIn: 'root'
})
export class SiteService {

  siteSelectionne$: Observable<AppDataUserSite | null> = this.dataService.user.observe().pipe(
    map(user => user?.sites || []),
    switchMap(sites => sites.length === 0
      ? of(null)
      : this.dataService.siteSelectionne.observe().pipe(
        map(siteId => siteId === null
          ? (sites.find(site => site.estPrincipale) || null)
          : (sites.find(site => site.id === siteId) || sites.find(site => site.estPrincipale) || null)
        )
      )
    )
  );

  siteSelectionneId$: Observable<number | null> = this.siteSelectionne$.pipe(
    map(site => site?.id || null)
  );

  siteSelectionneLabel$: Observable<string> = this.siteSelectionne$.pipe(
    map(site => site?.libelle || '')
  );

  userSites$ = this.dataService.user.observe().pipe(
    map(user => (user?.sites || []))
  );

  constructor(
    private dataService: DataService
  ) { }


  getSiteId(): Observable<number | null> {
    return this.siteSelectionneId$.pipe(take(1));
  }

  setSite(siteId: number) {
    this.dataService.siteSelectionne.set(siteId);
  }

}
