import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CModalFileViewComponent } from './c-modal-file-view.component';
import { IonicModule } from '@ionic/angular';
import { PdfViewerModule } from 'ng2-pdf-viewer';



@NgModule({
  declarations: [CModalFileViewComponent],
  imports: [
    CommonModule,
    IonicModule,
    PdfViewerModule,
  ],
  exports: [CModalFileViewComponent],
})
export class CModalFileViewModule { }
