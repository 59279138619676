<div class="c-box">
  <ng-container [ngSwitch]="dataIsSent">
    <ng-template [ngSwitchCase]="false">
      <ion-header>
        <ion-toolbar>
          <ng-container *ngIf="displayComplement">
            <ion-buttons slot="start">
              <ion-button (click)="onBackClick()" color="danger">
                <ion-icon slot="icon-only" name="caret-back-outline"></ion-icon>
              </ion-button>
            </ion-buttons>
          </ng-container>
          <ion-title mode="ios" color="danger">{{ displayComplement ? (contextPreparation ? entete2Preparation : entete2Edition) : entete }}</ion-title>
        </ion-toolbar>
      </ion-header>
      <div class="c-box-1">
        <ion-row class="ion-margin-vertical ion-justify-content-center">
          <fa-icon [icon]="['fas', 'triangle-exclamation']" class="c-warning-icon"></fa-icon>
        </ion-row>
        <ion-row class="ion-margin">
          <ion-text color="danger">
            <b>{{ contextPreparation ? blockedTextPreparation : blockedTextEdition }}</b>
          </ion-text>
        </ion-row>
        <ng-container *ngFor="let error of invalidFields">
          <ion-row class="ion-margin ion-justify-content-between ion-align-items-center ion-nowrap">
            <ion-text color="danger">
              <b>- {{ error.field.labelError ? error.field.labelError : error.field.label }}</b>
            </ion-text>
            <ion-badge color="danger">
              <ion-text color="white">
                NOGO
              </ion-text>
            </ion-badge>
          </ion-row>
        </ng-container>
        <ng-container [ngSwitch]="displayComplement">
          <ng-template [ngSwitchCase]="false">
            <ion-row class="ion-margin ion-justify-content-center">
              <ion-col size="12" class="ion-no-padding">
                <ion-button color="primary" (click)="onCheckAgainClick()" expand="block" shape="round">
                  <ion-label color="white">
                    {{ checkAgainText }}
                  </ion-label>
                </ion-button>
              </ion-col>
            </ion-row>
            <ion-row class="ion-margin ion-justify-content-center">
              <ion-col size="12" class="ion-no-padding">
                <ion-button color="danger" (click)="onValidateFormRegardlessClick()" expand="block" shape="round">
                  <ion-label color="white">
                    {{ validateFormRegardlessText }}
                  </ion-label>
                </ion-button>
              </ion-col>
            </ion-row>
          </ng-template>
          <ng-template [ngSwitchCase]="true">
            <ion-item lines="none">
              <textarea rows="4" [placeholder]="textareaPlaceholder" [formControl]="annulation_text"></textarea>
            </ion-item>
            <ion-row class="ion-margin-top ion-margin-horizontal ion-justify-content-center">
              <ion-col size="12" class="ion-no-padding">
                <ion-button [color]="annulation_vocal.value === null ? 'primary' : 'success'" (click)="onHandleVocalNoteButtonClick(annulation_vocal.value === null)" expand="block" shape="round">
                  <fa-icon [icon]="addVocalNoteIcon"></fa-icon>
                  <ion-label color="white">
                    {{ annulation_vocal.value === null ? addVocalNoteText : editVocalNoteText }}
                  </ion-label>
                </ion-button>
              </ion-col>
            </ion-row>
            <ion-row class="ion-margin-top ion-margin-horizontal ion-justify-content-center">
              <ion-col size="12" class="ion-no-padding">
                <ion-button [color]="annulation_video.value === null ? 'primary' : 'success'" (click)="onHandleVideoButtonClick(annulation_video.value === null)" expand="block" shape="round">
                  <fa-icon [icon]="addVideoIcon"></fa-icon>
                  <ion-label color="white">
                    {{ annulation_video.value === null ? addVideoText : editVideoText }}
                  </ion-label>
                </ion-button>
              </ion-col>
            </ion-row>
            <ion-row class="ion-margin-vertical ion-margin-horizontal ion-justify-content-center">
              <ion-col size="12" class="ion-no-padding">
                <ion-button [color]="annulation_photos.value === null ? 'primary' : 'success'" (click)="onHandlePhotoButtonClick(annulation_photos.value === null)" expand="block" shape="round">
                  <fa-icon [icon]="addPhotoIcon"></fa-icon>
                  <ion-label color="white">
                    {{ annulation_photos.value === null ? addPhotoText : editPhotoText }}
                  </ion-label>
                </ion-button>
              </ion-col>
            </ion-row>
          </ng-template>
        </ng-container>
        <ng-container *ngIf="displayComplement">
          <ion-toolbar>
            <ion-row class="ion-justify-content-center">
              <ion-button color="danger" class="c-confirm-button" (click)="onConfirmClick()" shape="round">
                <ion-label color="white">
                  {{ validationText }}
                </ion-label>
              </ion-button>
            </ion-row>
          </ion-toolbar>
        </ng-container>
      </div>
    </ng-template>
    <ng-template [ngSwitchCase]="true">
      <ion-header>
        <ion-toolbar>
          <ion-title mode="ios">{{ entete3 }}</ion-title>
        </ion-toolbar>
      </ion-header>
      <div class="c-box-1">
        <ion-row class="ion-margin">
          <ion-col size="12" class="ion-no-padding">
            {{ contextPreparation ? contextPreparation.sendDirectly ? isSentPreparationText : isSentPreparationCancelledText : isSentEditionText }}
          </ion-col>
        </ion-row>
        <ion-toolbar>
          <ion-row class="ion-justify-content-center">
            <ion-button color="danger" class="c-confirm-button" (click)="onExitAfterDataSentClick()" shape="round">
              <ion-label color="white">
                {{ exitText }}
              </ion-label>
            </ion-button>
          </ion-row>
        </ion-toolbar>
      </div>
    </ng-template>
  </ng-container>
</div>