import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { CModalInvalidFormComponent } from 'src/app/components/c-modal/c-modal-invalid-form/c-modal-invalid-form.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';



@NgModule({
  declarations: [CModalInvalidFormComponent],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    IonicModule,
    FontAwesomeModule
  ],
  exports: [CModalInvalidFormComponent],
})
export class CModalInvalidFormModule { }
