import { Injectable } from '@angular/core';
import { EMPTY, Observable, Subject, catchError, concatMap, defer, filter, firstValueFrom, forkJoin, map, merge, mergeMap, of, take, tap } from 'rxjs';
import { HttpService } from 'src/app/services/app/http.service';
import { DataService } from './data.service';
import { ApiReturn_Levage, App_FormCreatorJson, App_formDoc, App_ImagesSources, App_Levage_Updateable } from 'src/app/utils/interfaces';
import { HttpParams } from '@capacitor/core';
import { EmbedContextService } from './embed-context.service';
import { Enum_LevageStatus } from 'src/app/utils/enums';
import { instanceOfFieldLabel, removeFirstSlash } from 'src/app/utils/utils';
import { FileService } from 'src/app/file.service';
import { Directory } from '@capacitor/filesystem';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class SynchroService {

  constructor(
    private dataService: DataService,
    private embedContextService: EmbedContextService,
    private fileService: FileService,
    private httpService: HttpService,
  ) { }

  synchro(siteId: number) {
    return new Promise((resolve, reject) => {
      this.fetchDatas(siteId)
      .then(res => this.prepareDatas(res))
      .then(res => {
        const promises = [
          this.getAdditionalResources(res[0]),
          this.storeDatas(res)
        ];
        Promise.all(promises)
        .then(res => {
          resolve(res);
        })
      }
      )
      .catch(_ => reject(false))
    })
  }

  fetchDatas(siteId: number): Promise<[App_FormCreatorJson[], ApiReturn_Levage[]]> {
    return new Promise((resolve, reject) => {
      this.httpService.dataGetUserToken()
      .then(token => {
        const promises = firstValueFrom(forkJoin([
          this.fetchFormulaireListe(token, siteId),
          this.fetchLevageListe(token, siteId),
        ]));
        promises
        .then(res => {
          resolve(res);
        })
        .catch(_ => {
          // TODO-pf retour messages d'erreur
          reject(_);
        });
      })
      .catch(_ => {
        // TODO--pf retour messages d'erreur
        reject(_);
      });
    })
  }

  fetchAndStoreProjectImages(token: string): Promise<{ image: App_ImagesSources; success: boolean }[]> {
    return new Promise((resolve, reject) => {
      firstValueFrom(this.getProjectImageListe(token))
      .then(res => {
        let index = 0;
        const simultaneous = 5;
        const fiveFirst = res.slice(0, simultaneous);
        const subsequents = res.slice(simultaneous);
        const results: { image: App_ImagesSources, success: boolean }[] = [];
        const emitter$: Subject<number> = new Subject();

        const checkfilePresenceFetchIfNecessary$ = (image: App_ImagesSources) => defer(() => this.fileService.readFile(image.identifier, Directory.Data)).pipe(
          map(_ => ({ image, success: true })),
          catchError(err => this.fetchImage(image).pipe(
            map(_ => ({ image, success: true })),
            catchError(err => of({ image, success: false }))
          ))
        )

        const request$ = (elem: App_ImagesSources) => checkfilePresenceFetchIfNecessary$(elem).pipe(
          tap(_ => results.push(_)),
          map(_ => elem),
          tap(_ => {
            emitter$.next(index++);
          })
        );

        merge(
          ...fiveFirst.map(e => request$(e)),
          emitter$.pipe(
            filter(i => i <= subsequents.length),
            mergeMap(i => {
              const elem = subsequents[i];
              if (elem) {
                return request$(elem)
              } else {
                return EMPTY;
              }
            })
          )
        ).pipe(
          take(res.length),
        ).subscribe({
          complete: () => resolve(results)
        })
      })
      .catch(err => reject(err))
    })
  }

  fetchAndStoreFormulairesDocuments(formulaires: App_FormCreatorJson[]) {
    return new Promise((resolve, reject) => {
      const docs = this.getFormulairesDocuments(formulaires)

      let index = 0;
      const simultaneous = 5;
      const fiveFirst = docs.slice(0, simultaneous);
      const subsequents = docs.slice(simultaneous);
      const results: { image: App_formDoc, success: boolean }[] = [];
      const emitter$: Subject<number> = new Subject();

      const checkfilePresenceFetchIfNecessary$ = (image: App_formDoc) => defer(() => this.fileService.readFile(image.identifier, Directory.Data)).pipe(
        map(_ => ({ image, success: true })),
        catchError(err => this.fetchDocument(image).pipe(
          map(_ => ({ image, success: true })),
          catchError(err => of({ image, success: false }))
        ))
      )

      const request$ = (elem: App_formDoc) => checkfilePresenceFetchIfNecessary$(elem).pipe(
        tap(_ => results.push(_)),
        map(_ => elem),
        tap(_ => {
          emitter$.next(index++);
        })
      );

      merge(
        ...fiveFirst.map(e => request$(e)),
        emitter$.pipe(
          filter(i => i <= subsequents.length),
          mergeMap(i => {
            const elem = subsequents[i];
            if (elem) {
              return request$(elem)
            } else {
              return EMPTY;
            }
          })
        )
      ).pipe(
        take(docs.length),
      ).subscribe({
        complete: () => resolve(results)
      })
    });
  }

  getProjectImageListe(token: string) {
    const params: HttpParams = {};
    const _header: [string, string][] = [['Authorization', `Bearer ${token}`]];
    return this.httpService.apiGet<App_ImagesSources[]>({ uriTail: `/mobile/images` }, _header, params);
  }

  fetchFormulaireListe(token: string, siteId: number): Observable<App_FormCreatorJson[]> {
    const params: HttpParams = { site_id: `${siteId}` };
    const _header: [string, string][] = [['Authorization', `Bearer ${token}`]];
    return this.httpService.apiGet<App_FormCreatorJson[]>({ uriTail: `/formulaire/list` }, _header, params);
  }

  fetchLevageListe(token: string, siteId: number): Observable<ApiReturn_Levage[]> {
    const params: HttpParams = { site_id: `${siteId}` };
    const _header: [string, string][] = [['Authorization', `Bearer ${token}`]];
    return this.httpService.apiGet<ApiReturn_Levage[]>({ uriTail: `/levage/list` }, _header, params);
  }

  fetchLevageToValidateListe(token: string, siteId: number): Observable<ApiReturn_Levage[]> {
    const params: HttpParams = { site_id: `${siteId}` };
    const _header: [string, string][] = [['Authorization', `Bearer ${token}`]];
    return this.httpService.apiGet<ApiReturn_Levage[]>({ uriTail: `/levage/list-a-valider` }, _header, params);
  }

  prepareDatas(res: [App_FormCreatorJson[], ApiReturn_Levage[]]): Promise<[App_FormCreatorJson[], App_Levage_Updateable[]]> {
    return new Promise((resolve, reject) => {
      const promises: [Promise<App_FormCreatorJson[]>, Promise<App_Levage_Updateable[]>] = [
        this.prepareFormulaireListe(res[0]),
        this.prepareLevageListe(res[1]),
      ];
      Promise.all(promises)
      .then(res => {
        resolve(res);
      })
      .catch(_ => {
        // TODO--pf retour messages d'erreur
        reject(_);
      });

    });
  }

  prepareFormulaireListe(formulaireListe: App_FormCreatorJson[]): Promise<App_FormCreatorJson[]> {
    return new Promise((resolve, reject) => {
      resolve(formulaireListe);
    });
  }

  prepareLevageListe(levageListe: ApiReturn_Levage[]): Promise<App_Levage_Updateable[]> {
    return firstValueFrom(this.embedContextService.getEmbedContext())
      .then(embedContext => firstValueFrom(this.dataService.levageListe.get())
        .then(levageListeFromStorage => levageListeFromStorage || [])
        .then(levageListeFromStorage => {
          const levagesToStore = levageListe.reduce<App_Levage_Updateable[]>((levageListeToKeep, levage) => {
            const foundLevageFromStorageIndex = levageListeToKeep.findIndex(levageFromStorage => levage.id === levageFromStorage.data.id);
            if (!embedContext && (levage.etat_id === Enum_LevageStatus.TERMINE || levage.etat_id === Enum_LevageStatus.TERMINE_NON_REALISE)) {
              if (foundLevageFromStorageIndex !== -1) {
                levageListeToKeep.splice(foundLevageFromStorageIndex, 1);
              }
              return levageListeToKeep;
            } else {
              if (foundLevageFromStorageIndex !== -1) {
                if (!levageListeToKeep[foundLevageFromStorageIndex].app_update.toUpdate) {
                  levageListeToKeep.splice(foundLevageFromStorageIndex, 1, { app_update: { toUpdate: false }, data: levage });
                }
              } else {
                levageListeToKeep.push({ app_update: { toUpdate: false }, data: levage });
              }
              return levageListeToKeep;
            }
          }, levageListeFromStorage);
          return levagesToStore;
        })
      );
  }

  storeDatas(res: [App_FormCreatorJson[], App_Levage_Updateable[]]) {
    return new Promise((resolve, reject) => {
      const promises: Promise<any>[] = [
        this.storeFormulaireListe(res[0]),
        this.storeLevageListe(res[1]),
      ];
      Promise.all(promises)
      .then(res => {
        resolve(res);
      })
      .catch(_ => {
        // TODO--pf retour messages d'erreur
        reject(_);
      });
    });
  }

  storeFormulaireListe(formulaireListe: App_FormCreatorJson[]): Promise<App_FormCreatorJson[]> {
    return this.dataService.planLevageListe.set(formulaireListe);
  }

  storeLevageListe(levageListeToStore: App_Levage_Updateable[]): Promise<App_Levage_Updateable[]> {
    return this.dataService.levageListe.set(levageListeToStore);
  }

  getAdditionalResources(formulaires: App_FormCreatorJson[]) {
    return new Promise((resolve, reject) => {
      this.httpService.dataGetUserToken()
      .then(token => {
        this.fetchAndStoreProjectImages(token)
        .then(_ => this.fetchAndStoreFormulairesDocuments(formulaires))
        .then(_ => resolve(true))
        .catch(_ => reject(false))
      })
    })
  }

  fetchImage(image: App_ImagesSources) {
    return this.httpService.downloadImage(image.web).pipe(
      concatMap(base64 => defer(() => this.fileService.saveFile(base64, removeFirstSlash(image.identifier), Directory.Data)))
    )
  }

  fetchDocument(doc: App_formDoc) {
    return this.httpService.downloadDocument(doc.web).pipe(
      concatMap(base64 => defer(() => this.fileService.saveFile(base64, removeFirstSlash(doc.identifier), Directory.Data)))
    )
  }

  getFormulairesDocuments(formulaires: App_FormCreatorJson[]) {
    return formulaires.reduce<App_formDoc[]>((docs, form) => {
      return form.documents ?
      [
        ...docs,
        ...form.documents
      ]
      : docs
    }, []);
  }

  analyseIntegrityStoredData() {
    // TODO-pf
    // récupère les datas en base
    // les analyse
    // en cas de problème, suggère quelque chose
    // au pire, déconnecte
  }

  resetDatasOnLogout(): Promise<null> {
    return new Promise((resolve, reject) => {
      const deleteOperation = this.dataService.datasToClearOnLogout.map(dataToClear => dataToClear.remove());
      Promise.all(deleteOperation).then(_ => {
        resolve(null);
      })
    })

  }

}
