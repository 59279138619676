import { Component, Input, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, FormControl } from '@angular/forms';
import { Network } from '@capacitor/network';
import { IconName, IconPrefix } from '@fortawesome/fontawesome-common-types';
import { ActionSheetController, AlertController, LoadingController, ModalController } from '@ionic/angular';
import { firstValueFrom } from 'rxjs';
import { FileOpener } from '@ionic-native/file-opener/ngx';
import { SafeLiftService } from 'src/app/services/app/safelift.service';
import { RecordMediaService } from 'src/app/services/comp/record-media.service';
import { EmbedContextService } from 'src/app/services/app/embed-context.service';
import { App_FormCreatorJson, App_Levage_Updateable, App_LevageSend, App_TrueField } from 'src/app/utils/interfaces';
import { Media } from '@ionic-native/media/ngx';


@Component({
  selector: 'app-c-modal-invalid-form',
  templateUrl: './c-modal-invalid-form.component.html',
  styleUrls: ['./c-modal-invalid-form.component.scss'],
  providers: [RecordMediaService]
})
export class CModalInvalidFormComponent {
  @Input() invalidFields: {
    blocName: string;
    fieldName: string;
    fieldFormControl: AbstractControl<any, any>;
    field: App_TrueField;
  }[] = [];
  @Input() formData!: App_LevageSend
  @Input() levage!: App_Levage_Updateable | undefined
  @Input() formCreatorJson!: App_FormCreatorJson
  @Input() blocking!: boolean;
  @Input() contextPreparation: { sendDirectly: boolean } | undefined; // TODO-pf utiliser cette info pour déterminer si on propose de faire une copie ou non
  @Input() dataIsSent = false;


  entete = 'ATTENTION';
  entete2Preparation =  `PRÉPARATION IMPOSSIBLE`
  entete2Edition = `LEVAGE IMPOSSIBLE`
  entete3 = `INFORMATIONS ENREGISTRÉES`;

  blockedTextPreparation =  `Votre formulaire comporte des points bloquants. La préparation n'est pas possible dans ces conditions`;
  blockedTextEdition =  `Votre formulaire comporte des points bloquants. Le levage n'est pas possible dans ces conditions`;
  // errorListe = [
  //   `Le niveau de fuel est suffisant`,
  //   `Inspection avant demarrage ok`,
  // ];
  checkAgainText = `Je souhaite re-vérifier mon formulaire`;
  validateFormRegardlessText = `Je confirme que ces champs sont correctement remplis`;

  textareaPlaceholder = `Merci d'apporter des précisions complémentaires`;

  addVocalNoteIcon: [IconPrefix, IconName] = [`fas`, `volume-high`];
  addVocalNoteText = `ajouter une note vocale`;
  editVocalNoteText = `visualiser/effacer/modifier ma note vocale`;
  addVideoIcon: [IconPrefix, IconName] = [`fas`, `video`];
  addVideoText = `ajouter une vidéo`;
  editVideoText = `visualiser/effacer/modifier ma vidéo`;
  addPhotoIcon: [IconPrefix, IconName] = [`fas`, `camera`];
  addPhotoText = `ajouter une photo`;
  editPhotoText = `visualiser/effacer/modifier ma photo`;

  vocalNoteLocalUrl: string | null = null;
  videoLocalUrl: string | null = null;
  photoLocalUrl: string | null = null;

  validationText = `Je valide`;
  exitText = `Fermer`;

  displayComplement = false;

  annulation_text = this.formBuilder.control('');
  annulation_vocal = this.formBuilder.control<string | null>(null);
  annulation_video = this.formBuilder.control<string | null>(null);
  annulation_photos = this.formBuilder.control<string | null>(null);

  isSentPreparationText = `Les informations ont été enregistrées.`;
  isSentPreparationCancelledText = `Les informations ont été enregistrées.`;
  isSentEditionText = `Les informations ont été enregistrées et la fiche a été cloturée.`;

  form = this.formBuilder.group(
    {
      annulation_text: this.annulation_text,
      annulation_vocal: this.annulation_vocal,
      annulation_video: this.annulation_video,
      annulation_photos: this.annulation_photos,
    },
    {
      validators: fGroup => this.validationForm(fGroup)
    }
  )

  constructor(
    private actionSheetController: ActionSheetController,
    private alertController: AlertController,
    private embedContextService: EmbedContextService,
    private fileOpener: FileOpener,
    private media: Media,
    private formBuilder: FormBuilder,
    private loadingController: LoadingController,
    private modalController: ModalController,
    private recordMediaService: RecordMediaService,
    private safeLiftService: SafeLiftService
  ) { }

  onCheckAgainClick() {
    this.modalController.dismiss({
      action: `highlightErrors`
    });
  }

  onValidateFormRegardlessClick() {
    this.displayComplement = true;
    // TODO-pf
  }

  onHandleVocalNoteButtonClick(add: boolean) {
    if (add) {
      this.addVocalNote();
    } else {
      this.handleFileActionSheet(() => this.addVocalNote(), this.annulation_vocal, null, this.annulation_vocal.value, this.vocalNoteLocalUrl, 'audio');
    }
  }

  addVocalNote() {
    this.recordMediaService.takeAudio()
      .then(result => {
        if (Array.isArray(result)) {
          this.recordMediaService.treatMediaFile(result[0]).then(dataBase64 => {
            this.vocalNoteLocalUrl = result[0].fullPath;
            this.annulation_vocal.setValue(dataBase64)
          })
        }
      })
      .catch(err => {
        if (err?.code === 20) {
          this.alertController.create({
            message: `Cet appareil ne peut pas prendre en charge les enregistrements audio.`,
            buttons: [
              {
                text: `OK`,
              }
            ]
          }).then(alrt => alrt.present());
        } else {
          this.alertController.create({
            message: `Echec de l'enregistrement du fichier.`,
            buttons: [
              {
                text: `OK`,
              }
            ]
          }).then(alrt => alrt.present());
        }
      });

  }

  onHandleVideoButtonClick(add: boolean) {
    if (add) {
      this.addVideo()
    } else {
      this.handleFileActionSheet(() => this.addVideo(), this.annulation_video, null, this.annulation_video.value, this.videoLocalUrl, 'video');
    }
  }

  addVideo() {
    this.recordMediaService.takeVideo()
      .then(result => {
        if (Array.isArray(result)) {
          this.recordMediaService.treatMediaFile(result[0]).then(dataBase64 => {
            console.log('', dataBase64)
            this.videoLocalUrl = result[0].fullPath;
            this.annulation_video.setValue(dataBase64)
          })
        }
      })
      .catch(err => {
        if (err?.code === 20) {
          this.alertController.create({
            message: `Cet appareil ne peut pas prendre en charge les enregistrements vidéo.`,
            buttons: [
              {
                text: `OK`,
              }
            ]
          }).then(alrt => alrt.present());
        } else {
          this.alertController.create({
            message: `Echec de l'enregistrement du fichier.`,
            buttons: [
              {
                text: `OK`,
              }
            ]
          }).then(alrt => alrt.present());
        }
      });

  }


  onHandlePhotoButtonClick(add: boolean) {
    if (add) {
      this.addPhoto()
    } else {
      this.handleFileActionSheet(() => this.addPhoto(), this.annulation_photos, null, this.annulation_photos.value, this.photoLocalUrl, 'photo');
    }
  }

  addPhoto() {
    this.recordMediaService.takePhoto()
      .then(result => {
        if (Array.isArray(result)) {
          this.recordMediaService.treatMediaFile(result[0]).then(dataBase64 => {
            this.photoLocalUrl = result[0].fullPath;
            this.annulation_photos.setValue(dataBase64)
          })
        }
      })
      .catch(err => {
        if (err?.code === 20) {
          this.alertController.create({
            message: `Cet appareil ne peut pas prendre en charge la prise de photo.`,
            buttons: [
              {
                text: `OK`,
              }
            ]
          }).then(alrt => alrt.present());
        } else {
          this.alertController.create({
            message: `Echec de l'enregistrement du fichier.`,
            buttons: [
              {
                text: `OK`,
              }
            ]
          }).then(alrt => alrt.present());
        }
      });
  }

  handleFileActionSheet(addFunction: () => void, field: FormControl, nullValue: any, valueToRead: string | null, fileLocalUrl: string | null, fileType: 'audio' | 'video' | 'photo') {
    this.actionSheetController.create({
      buttons: [
        {
          text: `Visualiser`,
          handler: () => this.visualize(valueToRead, fileLocalUrl, fileType)
        },
        {
          text: `Modifier`,
          handler: () => addFunction()
        },
        {
          text: `Effacer`,
          role: `destructive`,
          handler: () => field.setValue(nullValue)
        },
        {
          text: `Annuler`,
          role: `cancel`
        },
      ]
    }).then(alertSheet => alertSheet.present());
  }

  visualize(value: string | null, fileLocalUrl: string | null, fileType: 'audio' | 'video' | 'photo') {
    if (fileType === 'audio') {
      if (typeof fileLocalUrl === 'string') {
        const mediaObject = this.media.create(fileLocalUrl);
        mediaObject.play();
        setTimeout(() => {
          const durationNumber = mediaObject.getDuration();
          setTimeout(() => {
            mediaObject.stop();
            mediaObject.release();
          }, (durationNumber * 1000 - 500));
        }, 500);
      }

    } else {
      if (typeof value === 'string' && typeof fileLocalUrl === 'string') {
        const mimeTypeRegex = /^data:(.*);base64,/;
        const match = value.match(mimeTypeRegex);
        if (match !== null) {
          const mimeType = match[1];
          if (typeof mimeType === 'string') {
            this.fileOpener.open(fileLocalUrl, mimeType)
              .catch(_ => this.alertController.create({
                header: 'Erreur',
                message: 'La pièce jointe est introuvable.',
                buttons: ['OK']
              }).then(alrt => alrt.present())
              );
          }
        }
      }
    }
  }

  async onConfirmClick() {
    const networkStatus = await Network.getStatus();
    if (this.form.valid) {
      const dataToSend = this.appendFormData(this.formData);

      const loading = await this.loadingController.create().then(loading => { loading.present(); return loading });
      this.sendOrStoreDataToSend(dataToSend, networkStatus.connected)
        .then(res => {
          loading.dismiss();
          this.dataIsSent = true
        })
        .catch(err => {
          loading.dismiss();
          this.alertController.create({
            message: `Échec de l'envoi de votre formulaire`,
            buttons: [`OK`]
          }).then(alrt => alrt.present());
        })
    } else {
      this.alertController.create({
        header: `Attention`,
        message: `Fournissez au moins un commentaire via le champ de texte, ou un extrait (audio, vidéo ou photo)`,
        buttons: [
          {
            text: `OK`,
          }
        ]
      }).then(alrt => alrt.present());
    }
  }

  sendOrStoreDataToSend(dataToSend: App_LevageSend, connected: boolean): Promise<App_Levage_Updateable> {
    const contextEmbed = firstValueFrom(this.embedContextService.getEmbedContext())
    const operation = (embed: boolean) => embed || connected
      ? this.sendThenStoreData(dataToSend)
      : this.storeData(dataToSend)
    return contextEmbed.then(embed => operation(embed).then(res => {
      return res;
    }));
  }

  async sendThenStoreData(dataToSend: App_LevageSend): Promise<App_Levage_Updateable> {
    // TODO-pf gérer l'échec
    return this.safeLiftService.sendThenStoreLevage(dataToSend, this.levage?.data?.id)
  }

  storeData(dataToSend: App_LevageSend) {
    return this.safeLiftService.storeLevage(dataToSend, this.formCreatorJson, this.levage, this.levage?.data?.id)
  }

  onExitAfterDataSentClick() {
    const dataToEmit = this.blocking
      ? {
        action: `cloture`
      }
      : {
        action: `goToNextStep`
      }
    this.modalController.dismiss(dataToEmit);

  }

  onBackClick() {
    this.displayComplement = false;
  }

  validationForm(formGroup: AbstractControl) {
    const value = formGroup.value;
    return (typeof value.annulation_text === 'string' && value.annulation_text.length > 0)
      || (typeof value.annulation_vocal === 'string' && value.annulation_vocal.length > 0)
      || (typeof value.annulation_video === 'string' && value.annulation_video.length > 0)
      || (typeof value.annulation_photos === 'string' && value.annulation_photos.length > 0)
      ? null
      : {
        err: `Au moins une information est nécessaire`
      }
  }

  appendFormData(formData: App_LevageSend): App_LevageSend {
    const value = this.form.value;
    const datasToAdd: { name: string; value: any; id?: number; }[] = [];

    if (typeof value.annulation_text === 'string' && value.annulation_text.length > 0) {
      datasToAdd.push({
        name: 'annulation_text',
        value: value.annulation_text
      });
    }
    if (typeof value.annulation_vocal === 'string' && value.annulation_vocal.length > 0) {
      datasToAdd.push({
        name: 'annulation_vocal',
        value: value.annulation_vocal
      });
    }
    if (typeof value.annulation_video === 'string' && value.annulation_video.length > 0) {
      datasToAdd.push({
        name: 'annulation_video',
        value: value.annulation_video
      });
    }
    if (typeof value.annulation_photos === 'string' && value.annulation_photos.length > 0) {
      datasToAdd.push({
        name: 'annulation_photos',
        value: value.annulation_photos
      });
    }
    return {
      ...formData,
      datas: [
        ...formData.datas,
        ...datasToAdd
      ]
    };
  }

}
