import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-c-modal-file-view',
  templateUrl: './c-modal-file-view.component.html',
  styleUrls: ['./c-modal-file-view.component.scss'],
})
export class CModalFileViewComponent {
  @Input({ required: true }) source!: string;
  @Input({ required: true }) type!: `img` | `video` | `audio` | `pdf`;

  zoom_to= 1;

  constructor() { }

  zoomIn () {
    if (typeof this.zoom_to === 'number') {
      this.zoom_to += 0.25;
    } else {
      this.zoom_to = 1;
    }
  }
  zoomOut () {
    if (typeof this.zoom_to === 'number') {
      if (this.zoom_to > 0.25) {
        this.zoom_to -= 0.25;
      }
    } else {
      this.zoom_to = 1;
    }
    
  }

}
