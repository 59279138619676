import { Injectable } from '@angular/core';
import { ApiReturn_TokenRefresh, App_FullUri } from '../../utils/interfaces';
import { environment } from 'src/environments/environment';
import { HttpParams } from '@capacitor/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { arrayIfNullOrUndefined, dirtyb64ToBlob } from '../../utils/utils';
import { DataService } from './data.service';
import { Observable, catchError, concatMap, defer, firstValueFrom, map, mergeMap, of, tap, throwError } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class HttpService {

  constructor(
    private dataService: DataService,
    private httpClient: HttpClient,
  ) { }

  // GET

  apiGet<T>(fullUri: App_FullUri, _headers: [string, string][] = [], params: HttpParams | undefined) {
    const uri = this.uriFrom(fullUri);
    const headers = this.makeHeader(_headers);
    return this.httpClient.get<T>(uri, { headers, params });
  }

  // PUT

  apiPut<C, R>(fullUri: App_FullUri, credentials: C, _headers: [string, string][] = []) {
    const uri = this.uriFrom(fullUri);
    const headers = this.makeHeader(_headers);
    return this.httpClient.put<R>(uri, credentials, { headers });
  }

  // POST

  apiPost<C, R>(fullUri: App_FullUri, credentials: C, _headers: [string, string][] = []) {
    const uri = this.uriFrom(fullUri);
    const headers = this.makeHeader(_headers);
    return this.httpClient.post<R>(uri, credentials, { headers });
  }

  makeHeader(_headers: [string, string][]): HttpHeaders {
    return arrayIfNullOrUndefined(_headers).reduce((acc, cur) => acc.append(cur[0], cur[1]), new HttpHeaders());
  }

  uriFrom(fullUri: App_FullUri) {
    return (fullUri.uriHead !== undefined ? fullUri.uriHead : environment.apiUrl) + fullUri.uriTail;
  }

  dataGetUserToken(): Promise<string> {
    // TODO-pf, faire une déco forcée ou un autre protocole pour forcer le user à se reconnecter pour récupérer ses identifiants
    return firstValueFrom(this.dataService.user.get()
      .pipe(
        catchError(err => throwError(() => new Error('Échec de récupération du token de connexion utilisateur'))),
        concatMap(user => {
          const token = user?.token;
          if (typeof token === 'string') {
            const splittedToken = token.split('.');
            const fragment = splittedToken[1];
            const json = atob(fragment);
            const parsed = JSON.parse(json);
            const dateExp = new Date(parsed.exp * 1000);
            const nowDate = new Date();
            const ecart = dateExp.getTime() - nowDate.getTime();
            if (ecart <= 0) {
              const token_refresh = user?.token_refresh
              if (token_refresh) {
                return this.apiTokenRefresh(token_refresh)
              } else {
                return throwError(() => new Error('Token de connexion périmé'));
              }
            }
            return of(token);
          } else {
            return throwError(() => new Error('Échec de récupération du token de connexion utilisateur'))
          }
        })
      )
    );
  }

  apiTokenRefresh(token_refresh: string): Observable<string> {
    const credentials = {
      'token_refresh': token_refresh
    };
    return this.apiPost<{ token_refresh: string }, ApiReturn_TokenRefresh>({ uriTail: `/user/refresh-token` }, credentials).pipe(
      mergeMap(res => defer(() => this.dataService.user.set(res)).pipe(map(_ => res.token)))
    );
  }

  apiLoginBack(token: string): Observable<any> {
    const credentials = {
      'token': token
    };
    return this.apiPost<{ token: string }, any>({ uriTail: `/user/login-back` }, credentials).pipe(
      tap(console.log),
      mergeMap(res => defer(() => this.dataService.user.set(res)).pipe(map(_ => res.token)))
    );
  }

  downloadImage(imgUrl: string): Observable<Blob> {
    return this.httpClient.get(imgUrl, { responseType: `blob` });
  }

  downloadDocument(imgUrl: string): Observable<Blob> {
    return this.httpClient.get(imgUrl, { responseType: `blob` });
  }

}
