import { inject } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';
import { DataService } from '../services/app/data.service';
import { map } from 'rxjs';

export const authGuard: CanActivateFn = (route, state) => {
  const auth = inject(DataService);
  const router = inject(Router);

  return auth.user.get().pipe(
    map(user => {
      if (user === null) {
        return router.parseUrl('/login');
      } else {
        return true;
      }
    })
  );
};
