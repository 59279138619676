import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { CModalDisplayDocumentsComponent } from 'src/app/components/c-modal/c-modal-display-documents/c-modal-display-documents.component';



@NgModule({
  declarations: [CModalDisplayDocumentsComponent],
  imports: [
    CommonModule,
    IonicModule,
    FontAwesomeModule
  ],
  exports: [CModalDisplayDocumentsComponent],
})
export class CModalDisplayDocumentsModule { }
