import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { IonicStorageModule } from '@ionic/storage-angular';
import { FileOpener } from '@ionic-native/file-opener/ngx';
import { Media } from '@ionic-native/media/ngx';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';

import { FontAwesomeModule, FaIconLibrary } from '@fortawesome/angular-fontawesome';
import { fas } from '@fortawesome/free-solid-svg-icons'
import { far } from '@fortawesome/free-regular-svg-icons'
import { fab } from '@fortawesome/free-brands-svg-icons'
import { CModalInvalidFormModule } from 'src/app/components/c-modal/c-modal-invalid-form/c-modal-invalid-form.module'
import { CModalDisplayDocumentsModule } from './components/c-modal/c-modal-display-documents/c-modal-display-documents.module';
import { MediaCapture } from '@awesome-cordova-plugins/media-capture/ngx';
import { CModalZoomImageModule } from './components/c-modal/c-modal-zoom-image/c-modal-zoom-image.module';
import { CModalFileViewModule } from './components/c-modal/c-modal-file-view/c-modal-file-view.module';

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    IonicModule.forRoot(),
    IonicStorageModule.forRoot(),
    AppRoutingModule,
    HttpClientModule,
    FontAwesomeModule,
    CModalInvalidFormModule,
    CModalDisplayDocumentsModule,
    CModalZoomImageModule,
    CModalFileViewModule
  ],
  providers: [
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    MediaCapture,
    FileOpener,
    Media,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {

  constructor(library: FaIconLibrary) { 
		library.addIconPacks(fas, fab, far);
	}

}
