import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CModalZoomImageComponent, StyleImgPipe } from './c-modal-zoom-image.component';
import { TrustHtmlModule } from 'src/app/pipes/trust-html/trust-html.module';
import { IonicModule } from '@ionic/angular';



@NgModule({
  declarations: [
    CModalZoomImageComponent,
    StyleImgPipe
  ],
  imports: [
    CommonModule,
    IonicModule,
    TrustHtmlModule
  ],
  exports: [CModalZoomImageComponent],
})
export class CModalZoomImageModule { }
