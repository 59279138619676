import { inject } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';
import { HttpService } from '../services/app/http.service';
import { catchError, concatMap, defer, map, of, tap } from 'rxjs';
import { EmbedContextService } from '../services/app/embed-context.service';

export const embedAuthGuard: CanActivateFn = (route, state) => {
  const embed = inject(EmbedContextService);
  const http = inject(HttpService);
  const router = inject(Router);
  const { redirectafter, token } = route.queryParams;


  return defer(() => embed.setEmbedContextTrue()).pipe(
    concatMap(_ => {
      if (typeof redirectafter !== 'string' || typeof token !== 'string') {
        return of(router.parseUrl('/login')); 
      } else {
        return http.apiLoginBack(token).pipe(
          map(result => router.parseUrl(redirectafter)),
          catchError(err => of(router.parseUrl('/login'))),
        );
      }
    })
  );
};
