import { Component, Input, OnInit, Pipe, PipeTransform } from '@angular/core';
import { ModalController } from '@ionic/angular';

@Component({
  selector: 'app-c-modal-zoom-image',
  templateUrl: './c-modal-zoom-image.component.html',
  styleUrls: ['./c-modal-zoom-image.component.scss'],
})
export class CModalZoomImageComponent {
  @Input({ required: true }) html!: any;

  constructor(private modalController: ModalController) { }

  closeModal() {
    this.modalController.dismiss();
  }

}

@Pipe({
  name: 'styleImgPipe'
})
export class StyleImgPipe implements PipeTransform {

  constructor() { }

  transform(html: string | null): string | null {
    return typeof html === `string`
  ? html.replace(/style="[^"]*"/, 'style="max-width: 300px; width: 50vw !important; height: auto !important;"')
    : html
  }

}