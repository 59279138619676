<div class="c-box">
  <ion-header>
    <ion-toolbar>
      <ion-title mode="md">{{ documents.length }} Document{{ documents.length > 1 ? 's' : '' }}</ion-title>
        <ion-button slot="end" (click)="closeModal()" fill="clear" color="dark">
          <ion-icon slot="icon-only" name="close"></ion-icon>
        </ion-button>
    </ion-toolbar>
  </ion-header>
  <div class="c-box-1">
    <ion-list>
      <ng-container *ngFor="let document of documents">
        <ion-item>
          <ion-label>{{ document.title }}</ion-label>
          <ion-button slot="end" (click)="onDocumentOpenClick(document)" shape="round">
            voir
          </ion-button>
        </ion-item>
      </ng-container>
    </ion-list>
  </div>
</div>