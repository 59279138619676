import { Component, Input } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { CModalFileViewComponent } from '../c-modal-file-view/c-modal-file-view.component';

@Component({
  selector: 'app-c-modal-display-documents',
  templateUrl: './c-modal-display-documents.component.html',
  styleUrls: ['./c-modal-display-documents.component.scss'],
})
export class CModalDisplayDocumentsComponent {
  @Input() documents: {
    title: string;
    data: null | string;
  }[] = [];

  constructor(private modalController: ModalController) { }

  closeModal() {
    this.modalController.dismiss();
  }

  async onDocumentOpenClick(document: { title: string; data: null | string; }) {
    if (document.data !== null) {
      this.modalController.create({
        component: CModalFileViewComponent,
        componentProps: {
          source: document.data,
          type: 'pdf'
        },
        cssClass: 'modal-file-view modal-pdf-view',
      }).then(_ => _.present())
    }
  }

}
