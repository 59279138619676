import { Injectable } from '@angular/core';
import { DataService } from './data.service';
import { Observable, concatMap, defer, firstValueFrom, map, of } from 'rxjs';
import { ApiReturn_Levage, App_FormCreatorJson, App_Levage_Updateable, App_LevageSend } from 'src/app/utils/interfaces';
import { HttpService } from 'src/app/services/app/http.service';
import { EmbedContextService } from './embed-context.service';
import { SynchroService } from './synchro.service';
import { SiteService } from './site.service';
import { Enum_LevageStatus } from 'src/app/utils/enums';

@Injectable({
  providedIn: 'root'
})
export class SafeLiftService {

  constructor(
    private dataService: DataService,
    private embedContextService: EmbedContextService,
    private httpService: HttpService,
    private siteService: SiteService,
    private synchroService: SynchroService,
  ) { }

  getAllLevages(): Observable<App_Levage_Updateable[]> {
    return this.embedContextService.getEmbedContext().pipe(
      concatMap(embedContext => embedContext
        ? this.getAllLevagesFromApi()
        : this.getAllLevagesFromStorage()
      )
    );
  }

  getAllLevagesFromApi(): Observable<App_Levage_Updateable[]> {
    return defer(() => this.httpService.dataGetUserToken()).pipe(
      concatMap(token => this.siteService.getSiteId().pipe(
        concatMap(siteId => siteId
          ? this.synchroService.fetchLevageListe(token, siteId)
          : of([])
        )
      )),
      map(levages => levages.map(levage => ({
        app_update: {
          toUpdate: false
        },
        data: levage
      })))
    );
  }

  getLevagesToValidateListeFromApi() {
    return defer(() => this.httpService.dataGetUserToken()).pipe(
      concatMap(token => this.siteService.getSiteId().pipe(
        concatMap(siteId => siteId
          ? this.synchroService.fetchLevageToValidateListe(token, siteId)
          : of([])
        )
      ))
    )
  }

  getAllLevagesFromStorage(): Observable<App_Levage_Updateable[]> {
    return this.dataService.levageListe.get().pipe(map(levageListe => levageListe || []));
  }

  getLevage(id: number | string): Observable<App_Levage_Updateable | undefined> {
    return this.embedContextService.getEmbedContext().pipe(
      concatMap(embedContext => embedContext
        ? this.getLevageFromApi(id)
        : this.getLevageFromStorage(id)
      )
    );
  }

  getLevageFromApi(id: number | string): Observable<App_Levage_Updateable> {
    return defer(() => this.httpService.dataGetUserToken()).pipe(
      concatMap(token => {
        const _header: [string, string][] = [['Authorization', `Bearer ${token}`]];
        const uriTail = `/levage/get`;
        const credentials = { id };
        return this.httpService.apiPost<{ id: string | number }, ApiReturn_Levage>({ uriTail }, credentials, _header).pipe(
          map(returnedLevage => ({
            app_update: {
              toUpdate: false
            },
            data: returnedLevage
          }))
        )
      })
    )
  }

  getLevageFromStorage(id: number | string): Observable<App_Levage_Updateable | undefined> {
    return this.getAllLevagesFromStorage().pipe(map(levageListe => levageListe.find(levage => levage.data.id === id)));
  }

  getAllPlansDeLevage(): Observable<App_FormCreatorJson[]> {
    return this.embedContextService.getEmbedContext().pipe(
      concatMap(embedContext => embedContext
        ? this.getAllPlansDeLevageFromApi()
        : this.getAllPlansDeLevageFromStorage()
      )
    );
  }

  getAllPlansDeLevageFromApi(): Observable<App_FormCreatorJson[]> {
    return defer(() => this.httpService.dataGetUserToken()).pipe(
      concatMap(token => this.siteService.getSiteId().pipe(
        concatMap(siteId => siteId
          ? this.synchroService.fetchFormulaireListe(token, siteId)
          : of([])
        )
      ))
    );
  }

  getAllPlansDeLevageFromStorage(): Observable<App_FormCreatorJson[]> {
    return this.dataService.planLevageListe.get().pipe(map(planLevageListe => planLevageListe || []));
  }

  getPlanDeLevage(id: number): Observable<App_FormCreatorJson | undefined> {
    return this.embedContextService.getEmbedContext().pipe(
      concatMap(embedContext => embedContext
        ? this.getPlanDeLevageFromApi(id)
        : this.getPlanDeLevageFromStorage(id)
      )
    );
  }

  getPlanDeLevageFromApi(id: number): Observable<App_FormCreatorJson> {
    return defer(() => this.httpService.dataGetUserToken()).pipe(
      concatMap(token => {
        const _header: [string, string][] = [['Authorization', `Bearer ${token}`]];
        const uriTail = `/formulaire/get`;
        const credentials = { id };
        return this.httpService.apiPost<{ id: string | number }, App_FormCreatorJson>({ uriTail }, credentials, _header);
      }
      )
    )
  }

  getPlanDeLevageFromStorage(id: number): Observable<App_FormCreatorJson | undefined> {
    return this.getAllPlansDeLevageFromStorage().pipe(map(planLevageListe => planLevageListe.find(planLevage => planLevage.id === id)));
  }

  sendThenStoreLevage(dataToSend: App_LevageSend, idElementToReplace: number | string | undefined): Promise<App_Levage_Updateable> {
    return new Promise((resolve, reject) => {
      this.sendLevage(dataToSend)
      .then(returnedLevage => {
        const levageUpdateable: App_Levage_Updateable = {
          app_update: {
            toUpdate: false
          },
          data: returnedLevage
        };
        return levageUpdateable;
      })
      .then(returnedLevage => this.addOrUpdateLevageInStore(returnedLevage, idElementToReplace).then(_ => resolve(_)))
      .catch(_ => {
        // TODO-pf retour messages d'erreur
        reject(_);
      });
    })
  }

  sendThenRemoveLevage(dataToSend: App_LevageSend): Promise<null> {
    return new Promise((resolve, reject) => {
      this.sendLevage(dataToSend)
      .then(returnedLevage => this.removeLevageInStore(returnedLevage.id).then(_ => resolve(_)))
      .catch(_ => {
        // TODO-pf retour messages d'erreur
        reject(_);
      });
    })

  }

  sendLevage(dataToSend: App_LevageSend) {
    return this.httpService.dataGetUserToken().then(token => {
      const _header: [string, string][] = [['Authorization', `Bearer ${token}`]];
      const uriTail = `/levage/save`;
      return firstValueFrom(this.httpService.apiPost<App_LevageSend, ApiReturn_Levage>({ uriTail }, dataToSend, _header));
    })
  }

  storeLevage(dataToStore: App_LevageSend, form: App_FormCreatorJson | undefined, existingLevage: App_Levage_Updateable | undefined, idElementToReplace: number | string | undefined): Promise<App_Levage_Updateable> {
    return new Promise(async (resolve, reject) => {
      const userInfos = await firstValueFrom(this.dataService.user.get());
      const responsableNom = userInfos?.name || '';
      const editedDatas = dataToStore.datas.reduce<{ name: string; value: any; id?: number; }[]>((datas, toStoreData) => {
        const foundInExistingDatasIndex = datas.findIndex(field => field.name === toStoreData.name);
        if (foundInExistingDatasIndex !== -1) {
          return [
            ...datas.slice(0, foundInExistingDatasIndex),
            {
              ...datas[foundInExistingDatasIndex],
              value: toStoreData.value
            },
            ...datas.slice(foundInExistingDatasIndex + 1)
          ]
        }
        return [...datas, toStoreData];
      }, (existingLevage?.data.datas || []))
      const levageToStore: App_Levage_Updateable = existingLevage
      ? {
        app_update: {
          toUpdate: true,
          data: dataToStore
        },
        data: {
          ...existingLevage.data,
          datas: editedDatas,
          step: dataToStore.step,
        }
      }
      : {
        app_update: {
          toUpdate: true,
          data: dataToStore
        },
        data: {
          ...dataToStore,
          id: this.generateFakeId(),
          reference: this.generateFakeReference(),
          created_at: this.generateFakeCreatedAt(),
          site_label: '',
          site_id: dataToStore.site_id,
          responsable_nom: responsableNom,
          responsable_prenom: '',
          responsable_trigramme: '',
          type_form: form?.label || '',
          form_id: dataToStore.form_id,
          etat: 'En cours',
          etat_id: dataToStore.etat_id,
          step: dataToStore.step,
          datas: editedDatas,
          documents: [],
        }
      };
      this.addOrUpdateLevageInStore(levageToStore, idElementToReplace)
      .then(_ => resolve(_))
      .catch(_ => reject(_))
    })
  }

  addOrUpdateLevageInStore(levageToStore: App_Levage_Updateable, idElementToReplace: number | string | undefined): Promise<App_Levage_Updateable> {
    return firstValueFrom(this.embedContextService.getEmbedContext()).then(embedContext => firstValueFrom(this.getAllLevagesFromStorage())
      .then(levageListe => levageListe || [])
      .then(levageListe => {
        const foundInListeIndex = typeof idElementToReplace === 'number' || typeof idElementToReplace === 'string'
        ? levageListe.findIndex(levage => levage.data.id === idElementToReplace)
        : -1
        const deleteLevage = !embedContext && (levageToStore.data.etat_id === Enum_LevageStatus.TERMINE || levageToStore.data.etat_id === Enum_LevageStatus.TERMINE_NON_REALISE )
        const listTostore: App_Levage_Updateable[] = deleteLevage
        ? foundInListeIndex !== -1
          ? [
            ...levageListe.slice(0, foundInListeIndex),
            ...levageListe.slice(foundInListeIndex + 1)
          ]
          : [
            ...levageListe
          ]
        : foundInListeIndex !== -1
          ? [
            ...levageListe.slice(0, foundInListeIndex),
            levageToStore,
            ...levageListe.slice(foundInListeIndex + 1)
          ]
          : [
            ...levageListe,
            levageToStore
          ];
        return this.dataService.levageListe.set(listTostore).then(_ => {
          return levageToStore
        });
      })
    )

  }

  removeLevageInStore(idElementToRemove: string | number): Promise<null> {
    return firstValueFrom(this.getAllLevagesFromStorage())
    .then(levageListe => levageListe || [])
    .then(levageListe => {
      const foundInListeIndex = typeof idElementToRemove === 'number' || typeof idElementToRemove === 'string'
      ? levageListe.findIndex(levage => levage.data.id === idElementToRemove)
      : -1

      const listTostore = foundInListeIndex !== -1
      ? [
        ...levageListe.slice(0, foundInListeIndex),
        ...levageListe.slice(foundInListeIndex + 1)
      ]
      : [
        ...levageListe
      ];
      return this.dataService.levageListe.set(listTostore).then(_ => {
        return null;
      });
    })

  }

  resetLevageListe() {
    return this.dataService.levageListe.remove();
  }
  resetPlanLevageListe() {
    return this.dataService.planLevageListe.remove();
  }

  generateFakeId() {
    const date = new Date();
    return `NEW_${date.getTime()}`
  }

  generateFakeReference() {
    // const date = new Date();
    // const year = `0000${date.getFullYear()}`.slice(-4)
    // const month = `00${date.getMonth() + 1}`.slice(-2)
    // const day = `00${date.getDate()}`.slice(-2)
    // return `BROUILLON_${day}-${month}-${year}`
    return `non défini`;
  }

  // TODO-pf attention si plus tard on veut que e soit un paramètre gérable
  generateFakeCreatedAt() {
    const date = new Date();
    const year = `0000${date.getFullYear()}`.slice(-4);
    const month = `00${date.getMonth() + 1}`.slice(-2);
    const day = `00${date.getDate()}`.slice(-2);

    const hours = `00${date.getHours()}`.slice(-2);
    const minutes = `00${date.getMinutes()}`.slice(-2);
    const seconds = `00${date.getSeconds()}`.slice(-2);
    console.log(`${year}-${month}-${day} ${hours}:${minutes}:${seconds}`)

    return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`
  }
}
