import { Injectable } from '@angular/core';
import { Directory, Filesystem } from '@capacitor/filesystem';

@Injectable({
  providedIn: 'root'
})
export class FileService {

  constructor() { }

  readFile(path: string, directory?: Directory) {
    return Filesystem.readFile({
      path,
      directory
    });
  }

  saveFile(blobtoSave: Blob, toPath: string, directory: Directory) {
    const splittedToPath = toPath.split(`/`);
    const pathWithoutFileName = splittedToPath.slice(0, splittedToPath.length - 1).join('/');
    const fileName = splittedToPath.slice(splittedToPath.length - 1)[0];
    console.log(splittedToPath);
    console.log(pathWithoutFileName)
    console.log(fileName)
    return new Promise((resolve, reject) => {
      Filesystem.mkdir({
        path: pathWithoutFileName,
        directory,
        recursive: true
      })
        .finally(() => Filesystem.writeFile({
          path: toPath,
          data: blobtoSave,
          directory
        })
          .then(() => resolve(fileName))
          .catch((err) => reject(fileName))
        );
    })
  }

  copyInCache(data: Blob, path: string) {
    return Filesystem.writeFile({
      path,
      data,
      directory: Directory.Cache
    });
  }

}
